//styles override
body {
	font-family: 'Mukta', sans-serif;
}

.print-heading {
	display: none;
}
.bg-custom {
	background-color: #034ea0;
}

@media print {
	.print-heading {
		display: block !important;
	}
	.action {
		display: none !important;
	}
	@page {
		size: A4;
		margin: 20px !important;
	}
}

.current-balance {
	color: red;
	font-size: 10px;
}

.debit {
	color: green;
}

.balance {
	color: black;
}

.textCapitalize {
	text-transform: capitalize;
}

.css-1ajefys-Control {
	width: 100% !important;
	background-color: #d8dbe0 !important;
}

.employee-img {
	width: 100px;
	height: 100px;
	object-fit: cover;
}

.balanceSheet tr th,
.balanceSheet tr td {
	padding: 4px;
}

.pointer {
	cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

.modal-header .close span {
	font-size: 2rem !important;
}

.item-modal {
	width: 80vw;
	max-width: 80vw;
	height: 80vh;
	max-height: 80vh;
}
